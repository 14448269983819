.process-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.images-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

.image-section {
  flex: 1;
  text-align: center;
  max-width: 48%;
}

.image-section img {
  width: 100%;
  height: auto;
  max-height: 50vh;
  object-fit: contain;
  border: 2px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px;
  background-color: white;
}

.parameters-download-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  gap: 20px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
}

.sliders-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  /* Réduction de l'espace entre les sliders */
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.slider-container input {
  width: 60px;
  /* Réduction de la largeur des champs */
  padding: 5px;
  margin-left: 10px;
  /* Espace entre le label et le champ */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.apply-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  /* Espacement à gauche du bouton */
}

.apply-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

/* Bouton download ajusté */
.download-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  /* Espace entre le bouton et le texte */
}

.download-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #0056b3;
}

.image-section-right {
  position: relative;
}

.image-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Réduction de l'espace sur les petits écrans */
@media only screen and (max-width: 768px) {
  .process-container {
    padding: 0px;
    gap: 10px;
    /* Ajustement de l'espace général sur mobile */
  }

  .parameters-download-container {
    padding: 10px;
    flex-direction: column;
    /* Les éléments seront empilés verticalement */
    align-items: flex-start;
  }

  .apply-button {
    margin-top: 10px;
    /* Ajouter un espace au-dessus du bouton Valider */
    margin-left: 0px;
    /* Supprimer l'espace à gauche */
  }

  .download-button {
    margin-left: 0px;
    /* Supprimer l'espace à gauche */
    margin-top: 10px;
    /* Ajouter un espace avant le bouton Download */
    width: 100%;
    /* S'assurer que le bouton occupe toute la largeur */
  }
}

@media only screen and (max-width: 480px) {
  .image-section h2 {
    font-size: 1rem;
  }
}

/* Texte à côté du bouton Download sur grand écran */
@media only screen and (min-width: 769px) {
  .download-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .download-text {
    font-size: 1rem;
    color: #333;
  }
}