body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
}

.app-container {
  text-align: center;
  padding: 20px;
}

.project-title {
  font-size: 2.5rem;
  /* Taille du titre par défaut sur les grands écrans */
  margin-bottom: 20px;
}

/* Réduction de la taille du titre sur les petits écrans */
@media only screen and (max-width: 768px) {
  .project-title {
    font-size: 2rem;
    /* Taille réduite pour les écrans de moins de 768px */
  }
}

@media only screen and (max-width: 480px) {
  .project-title {
    font-size: 1.5rem;
    /* Taille encore plus petite pour les très petits écrans */
  }
}

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.explanation-text {
  margin-top: 20px;
  font-size: 1rem;
  color: #666;
}